import * as React from "react";
import { Helmet } from "react-helmet";
import { meta_title } from "../components/Meta";
import AutoplayVideo from "../components/Video/AutoplayVideo";
import FullWidthVideo from "../components/Video/FullWidthVideo";
import Ticker from "../components/Ticker/Ticker";
import PrimaryButton from "../components/Buttons/PrimaryButton";
import { useRef, useEffect } from "react";
import PFPTicker from "../components/svg/PFPTicker";
import { getDeviceType } from "../components/Utils/DeviceType";
import ANFTTicker from "../components/svg/ANFTTicker";

const AnimatronicNftsPage = () => {
  const [heroVideo, setHeroVideo] = React.useState(null);
  const [heroPoster, setHeroPoster] = React.useState(null);

  useEffect(() => {
    const heroVideoSizes = {
      mobile: "/videos/Gojira_aNFT_mobile.mp4",
      tablet: "/videos/Gojira_aNFT_sm.mp4",
      desktop: "/videos/Gojira_aNFT_md.mp4",
      largeDesktop: "/videos/Gojira_aNFT_lg.mp4",
    };

    const heroVideoPosters = {
      mobile: "/videos/Gojira_aNFT_mobile_poster.jpg",
      tablet: "/videos/Gojira_aNFT_sm_poster.jpg",
      desktop: "/videos/Gojira_aNFT_md_poster.jpg",
      largeDesktop: "/videos/Gojira_aNFT_lg_poster.jpg",
    };

    function updateVideo() {
      setHeroVideo(heroVideoSizes[getDeviceType()]);
      setHeroPoster(heroVideoPosters[getDeviceType()]);
    }

    updateVideo();
    window.addEventListener("resize", updateVideo);

    return () => {
      window.removeEventListener("resize", updateVideo);
    };
  }, []);

  return (
    <>
      {/* @ts-ignore */}
      <Helmet>
        <title>Animatronic NFTs | {meta_title}</title>
        <meta
          property="og:image"
          content={"https://gojiralabs.com/favicon/meta-aNFT.jpg"}
        />
        <meta
          property="og:url"
          content="https://gojiralabs.com/animatronic-nfts/"
        />
        <meta
          property="twitter:url"
          content="https://gojiralabs.com/animatronic-nfts/"
        />
      </Helmet>
      <div className="bg-black absolute top-0 left-0 w-full h-0 md:h-[105px] block z-10"></div>

      <div
        className={`relative w-full block z-0`}
        style={{ minHeight: "62.5vw" }}
      >
        {heroVideo && (
          <>
            <FullWidthVideo
              mp4={heroVideo}
              poster={heroPoster}
              className={`bg-black w-full h-auto fixed`}
            />
          </>
        )}
      </div>
      <div className="z-10 relative">
        <Ticker
          direction={"ltr"}
          duration={50}
          className="bg-green-dark py-2.5 md:py-2.5"
        >
          <ANFTTicker />
        </Ticker>
        <Ticker
          direction={"rtl"}
          duration={50}
          className={`bg-white py-1 md:hidden`}
          isSticky={false}
        >
          <PFPTicker />
        </Ticker>

        {/* ANFT Intro */}
        <div className={`bg-[#000000] text-white px-7 pt-16 pb-16 md:py-40`}>
          <div
            className="md:flex md:flex-row-reverse md:items-center mx-auto"
            style={{ maxWidth: 1600 }}
          >
            <div className={`w-full md:w-1/2 block`}>
              <div className={`block max-w-[600px] mx-auto`}>
                <h1 className="text-green font-inter font-extralight text-[30px] leading-[34px] mb-4 md:mb-5">
                  Animatronic NFTs (aNFTs)
                  <br className={`hidden md:block`} />
                  bring PFP collections to life
                </h1>
                <p className={`p mb-4 md:mb-5`}>
                  Love ‘em or hate ‘em, there’s no denying that profile picture
                  (PFP) NFT projects have created vibrant online communities of
                  devoted holders. That’s why we thought it would be fun to do
                  more with these characters than just show them off on social
                  media. What if you could play with them?
                </p>
                <p className={`p mb-4 md:mb-5`}>
                  We invented animatronic NFTs (aNFTs) to take PFPs to the next
                  level. aNFTs are a new format of digital collectible that
                  provide all the necessary body components of a playable,
                  animated character. To make these monsters come alive, we also
                  built an innovative game animation rig that makes it easy to
                  bring new characters into games.
                </p>
                <p className={`p mb-10`}>
                  The result? Your PFP can escape its tiny square on social
                  media and run, jump and win.
                </p>
              </div>
            </div>
            <div className={`w-full md:w-1/2 block`}>
              <img
                src={`/images/anft/Dogeparts.png`}
                alt={`Exploded view of a Weredoge from blockxer`}
                className={`w-full h-auto max-w-[400px] mx-auto mb-2 md:mb-10`}
              />
              <p className="text-green text-center uppercase font-mdio text-xs">
                Exploded view of a Weredoge
                <br />
                from{" "}
                <a
                  href={`https://blockxer.com/`}
                  target={`_blank`}
                  className={`underline font-mdio`}
                >
                  Blockxer
                </a>
              </p>
            </div>
          </div>
        </div>

        <Ticker
          direction={"rtl"}
          duration={50}
          className={`bg-white py-2.5 hidden md:block`}
          isSticky={false}
        >
          <PFPTicker />
        </Ticker>

        {/* Gooji Intro */}
        <div className={`bg-grey text-white px-7 pt-16 pb-16 md:py-40`}>
          <div
            className="md:flex md:flex-row md:items-start mx-auto"
            style={{ maxWidth: 1600 }}
          >
            <div className={`w-full md:w-1/2 block`}>
              <div className={`block max-w-[600px] mx-auto md:px-6`}>
                <h2 className="text-green font-inter font-extralight text-[30px] leading-[34px] mb-4 md:mb-5">
                  Meet Gooji: a gooey flexible skeleton built for animated fun
                </h2>
                <img
                  src={`/images/anft/Gooji.png`}
                  alt={`Gooji`}
                  className={`w-full h-auto max-w-[240px] mx-auto mt-6 mb-12 md:hidden`}
                />
                <p className={`p mb-16 md:mb-36`}>
                  Say “hello” to Gooji – the innovation that brings aNFTs to
                  life! Gooji’s gooey character is a software framework that
                  provides a uniquely flexible animation rig, and allows for
                  quick deployment of nearly any kind of character. From
                  adjustable body parts and size, to the finer details of
                  movements and color, Gooji enables a player’s PFP to be
                  animated. The exploded-view components provided by the aNFT
                  format are seamlessly overlayed onto Gooji’s flexible rig and
                  made playable as an in-game character.
                </p>
                <img
                  src={`/images/anft/Gooji.png`}
                  alt={`Gooji`}
                  className={`w-full h-auto max-w-[640px] mx-auto hidden md:block mt-6 mb-12 md:mb-10`}
                />
              </div>
            </div>
            <div className={`w-full md:w-1/2 block`}>
              <div className={`block max-w-[600px] mx-auto md:px-6`}>
                <h2 className="text-green font-inter font-extralight text-[30px] leading-[34px] mb-4 md:mb-5">
                  aNFTs are a new format of digital collectible
                </h2>
                <p className={`p mb-4 md:mb-5`}>
                  aNFTs look sort of like Frankenstein’s monster before it got
                  stitched together, though maybe a little less gross. They
                  provide an exploded view of every body part needed to spawn a
                  full-body PFP character – artwork that can then be overlaid
                  onto an animation rig and quickly deployed as a playable
                  avatar in-game.
                </p>
                <p className={`p mb-10 md:mb-20`}>
                  Today, you can try a simulated aNFT experience in the new
                  running game by Gojira Labs: Sprinft. Holders of select PFP
                  collections can connect a wallet to unlock a playable PFP
                  avatar. In the near future, they'll be able to spawn and
                  collect their very own aNFT.
                </p>
                <FullWidthVideo
                  mp4={"/videos/anft-run.mp4"}
                  className={`bg-black w-full h-auto mb-8`}
                  poster={"/videos/anft-run_poster.jpg"}
                />
                <p className={`p mb-4 md:mb-5`}>
                  Example gameplay from Sprinft, an aNFT running game developed
                  by Gojira Labs, available to play now.
                </p>
                <PrimaryButton
                  url={`https://sprinft.gojiralabs.com`}
                  className={`table mx-auto md:ml-0 mb-24`}
                  color={"red"}
                  hover={"green"}
                >
                  Play
                </PrimaryButton>
                <h2 className="text-green font-inter font-extralight text-[30px] leading-[34px] mb-4 md:mb-5">
                  Future of aNFTs: gaming, the metaverse and beyond
                </h2>
                <p className={`p mb-4 md:mb-5`}>
                  With the aNFT format paired with our friendly neighborhood
                  animated skeleton, Gooji, we’re just beginning to unlock a
                  whole world of fun with PFP collections. We’ll continue to
                  work with PFP collections and onboard them to the aNFT format,
                  and Gooji’s animation capabilities will become more and more
                  automated. (AI, anyone?)
                </p>
                <p className={`p mb-4 md:mb-5`}>
                  But why stop at drawings of monkeys and penguins? Imagine a
                  world where you could scan yourself in 3D, mint your scan as
                  an aNFT and overlay it onto a 3D animation rig. Imagine
                  playing yourself in your favorite game, or just hanging out in
                  the metaverse.
                </p>
                <p className={`p mb-10`}>
                  The sky’s the limit – what kind of monsters will you make with
                  aNFTs?
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AnimatronicNftsPage;
